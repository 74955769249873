import router from "../router";

export default class Helper {
    static showSpinner() {
        //Show loading. This is the only way to make it appear before the app is mounted.
        let spinnerWrapper = document.createElement('div');
        spinnerWrapper.className = 'spinner-wrapper';
        let spinner = document.createElement('div');
        spinner.className = 'spinner';
        spinnerWrapper.appendChild(spinner);
        let spinnerStyle = document.createElement('style');
        spinnerStyle.textContent = `.spinner {
          width: 40px;
          height: 40px;
          background-color: #333;
        
          margin: 100px auto;
          -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
          animation: sk-rotateplane 1.2s infinite ease-in-out;
        }
        
        @-webkit-keyframes sk-rotateplane {
          0% { -webkit-transform: perspective(120px) }
          50% { -webkit-transform: perspective(120px) rotateY(180deg) }
          100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
        }
        
        @keyframes sk-rotateplane {
          0% { 
            transform: perspective(120px) rotateX(0deg) rotateY(0deg);
            -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
          } 50% { 
            transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
            -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
          } 100% { 
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
          }
        }`;
        spinnerWrapper.appendChild(spinnerStyle);
        let appDiv = document.getElementById('app');
        appDiv.appendChild(spinnerWrapper);
    }

    static performRedirect(state,route = '/home'){
        if (state.toClient && state.toClient.redirectUrl) {
            window.location = state.toClient.redirectUrl;
        } else {
            router.replace(route);
        }
    }
    static redirectToAcessPage(app){
      localStorage.clear();
      app.$router.push("/");
      app.$router.go();
      app.$store.dispatch("isRepAuthenticated", false);
      app.$store.dispatch("landingInfo", null);
      app.$store.dispatch("landingUrl", null);
      app.$store.dispatch("repInfo", null);
      app.$store.dispatch("groupInfo", null);
      app.$store.dispatch("zipCode", null);
      app.$store.dispatch("isOver65", false);
      app.$store.dispatch("continueEnrollmentInfo", false);
      app.$store.commit('MUTATION_SET_ERROR', false);
    }
}