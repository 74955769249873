<template>
  <div class="toggle-container">
    <div class="toggle-label text-justify">{{ageRestriction ? 'Selected Age Is Over 65' : 'Selected Age Is Under 65'}}</div>
    <div class="form-check form-switch">
      <div class="mt-2 d-flex justify-content-start">
        <input class="form-check-input toggle-custom-size"
               type="checkbox"
               v-model="ageRestriction"
               @change="renderPopUpModel"
               role="switch"
               id="flexSwitchCheckDefault">
      </div>
    </div>
    <div>
      <div>
        <b-modal id="ageToggle"
                 centered
                 no-close-on-backdrop
                 size="md"
                 v-model="show">
          <template #modal-header>
            <div class="popUp-header">
              <h4 class="text-center">{{ageRestriction ? 'Do you want to select age range over 65 ?' : 'Do you want to select age range under 65 ?'}}</h4>
              <b-button variant="close-btn" @click="closePopupModel">
                <i class="fas fa-times"></i>
              </b-button>
            </div>
          </template>
          <b-container fluid>
            <div class="popUp-details text-center">
              <div class="action-btn text-center mt-4">
                <b-button style="width:40%;"
                          class="button-brand mx-1"
                          @click="submitSelectedAge"
                          data-dismiss="modal">Yes</b-button>
                <b-button style="width:40%;"  class="dark-btn mx-1" @click="closePopupModel">No</b-button>
              </div>
            </div>
          </b-container>
        </b-modal>
      </div>
      <div>
        <button class="grp-home-btn" @click="routeToGroupHome">Group Home</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AgeToggle",
  data () {
    return {
      ageRestriction: false,
      show: false
    }
  },
  mounted () {
    let app = this
    app.ageRestriction = app.$store.getters.isOver65
  },
  methods: {
    renderPopUpModel() {
      let app = this
      app.show = true
    },
    submitSelectedAge () {
      let app = this
      app.$store.dispatch('isOver65', app.ageRestriction)
      app.show = false;
      axios.defaults.headers.common['is-over-65'] = app.ageRestriction
      app.$bus.$emit('getMenuList');
      if (app.$route.name == 'main') {
        app.$bus.$emit('getGroupDetail');
      } else if (app.$route.name == 'NewPlans') {
        app.$bus.$emit('getFilterPlanList');
      } else if (app.$route.name == 'plans') {
        app.$bus.$emit('renderAgeBasedPlan');
      }
    },
    closePopupModel () {
      let app = this
      app.show = false;
      app.ageRestriction =  !app.ageRestriction
    },
    routeToGroupHome () {
      let app = this
      app.$router.push('/groups-home')
    }
  }
}
</script>

<style scoped lang="scss">
.toggle-custom-size {
  width: 60px !important;
  height: 28px !important;
  cursor: pointer;
}
.toggle-label {
  font-size: 12px;
  font-weight: 500;
}
.toggle-container {
  width: 105px;
  background-color: white;
  padding: 10px 8px;
  position: fixed;
  top: 45%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  z-index: 2;
}
.grp-home-btn{
  font-size: 10px;
  color: #fff;
  background: #000;
  padding: 6px 14px;
  border-radius: 6px;
  display: inline-block;
  transition: all ease 0.4s;
  margin-top: 0.6rem;
  border: none;
  font-weight: bold;
}
</style>